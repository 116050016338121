import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      tyra: file(relativePath: { eq: "content/tyra-2.png" }) {
        childImageSharp {
          fixed(width: 490) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Förskoleappen Tyra" />
      <Layout>
        <div className="py-16 bg-warm-grey-50 overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-warm-grey-900 sm:text-4xl sm:leading-10">
                Tyra
              </h3>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-warm-grey-500">
                Vi använder förskoleappen <a href="https://tyra-appen.se/" target="_blank" className="font-semibold text-bb-red-600 hover:text-bb-red-500 transition ease-in-out duration-150">Tyra</a> för all dialog mellan föräldrar och personal.
              </p>
            </div>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-extrabold text-warm-grey-900 tracking-tight sm:text-3xl sm:leading-9">
                  En överblick
                </h4>
                <p className="mt-3 text-lg leading-7 text-warm-grey-500">
                  För personalen ger det effektiv administrering av scheman och barn, för föräldrar ger det en unik inblick i vardagen för sitt barn.
                </p>

                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Hantera schema</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Man kan enkelt lägga/justera sitt schema direkt i appen.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Dokumentation</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Få insyn i verksamheten.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Senaste händelser</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Håll dig uppdaterad om vad som sker.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Sjukanmälan</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Meddela enkelt personalen om när ni behöver VABa
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 flex">
                <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                  <defs>
                    <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                </svg>
                <Img className="relative mx-auto" fixed={data.tyra.childImageSharp.fixed} />
              </div>
            </div>

          </div>
        </div>
      </Layout>
    </>
  )
}



export default Page
